//  零售-商品列表
import { mapState } from 'vuex';
import { Toast } from 'vant';
import CustomStepper from '@/components/common/custom-stepper/index.vue';
import Rmb from '@/components/common/rmb/index.vue';
import { getDiscountPrice } from '@/utils/common';
import { postGift } from '@/service/cart/index';

export default {
    name: 'order-product-item',

    components: {
        CustomStepper,
        Rmb,
    },

    props: {
        productItem: {
            type: Object,
            default: () => {
                return {
                    productId: 'fzkl001',
                    name: '肥宅快乐水',
                    inventory: 999,
                    quantity: 0,
                    thumb: 'http://7xr2vd.com1.z0.glb.clouddn.com/FnLNf7WPeVYhMTsbQMdtYirBNXct',
                    formatName: '规格名称',
                    convertCount: 40,
                    showUnit: '箱',
                    unit: '盒',
                };
            },
        },
        isStep: {
            type: Boolean,
            default: false,
            required: false,
        },
    },

    data() {
        return {
            counts: {},
            currentGift: [],
            currentPackageId: null,
        };
    },

    computed: {
        ...mapState({
            limitProduct: state => state.product.limitProduct,
            userId: state => state.user.user.id,
        }),

        minstep() {
            return JSON.parse(this.productItem.minstep)[Number(this.productItem.userRank)] || 1;
        },

        minusStep() {
            if (!JSON.parse(this.productItem.decMinStep)) {
                if (!Object.keys(JSON.parse(this.productItem.decMinStep)).length) {
                    return this.minstep;
                }
            }
            return JSON.parse(this.productItem.decMinStep)[Number(this.productItem.userRank)] || 1;
        },

        totalPrice() {
            return Object.values(this.counts).reduce((result, item) => {
                return (
                    result +
                    getDiscountPrice(JSON.parse(this.productItem.discount), this.productItem.userRank, item.price) *
                        item.count
                );
            }, 0);
        },

        totalCount() {
            return Object.values(this.counts).reduce((result, item) => result + item.count, 0);
        },
    },

    created() {
        this.initCounts();
    },

    methods: {
        getDiscountPrice,

        // 初始化
        initCounts() {
            this.counts = {};
            this.productItem.formats.forEach(item => {
                this.$set(this.counts, item.id, {
                    name: item.name,
                    count: this.min,
                    price: item.price,
                    convertCount: item.convertCount,
                    showUnit: item.showUnit,
                });
            });
        },

        reload() {
            this.$nextTick(function() {
                this.counts = {};
                this.productItem.formats.forEach(item => {
                    this.$set(this.counts, item.id, {
                        name: item.name,
                        count: this.min,
                        price: item.price,
                        convertCount: item.convertCount,
                        showUnit: item.showUnit,
                    });
                });
            });
        },

        isLimit(format) {
            const { limitProduct } = this;
            const isLimitProduct = limitProduct[format.id];
            if (isLimitProduct === 0) {
                return false;
            }
            return format.inventory > 0;
        },

        onOverLimitProduct(format) {
            const { limitProduct } = this;
            const limit = limitProduct[format.id];
            if (!limit) {
                return;
            }
            Toast(`亲，限购商品最多只能选${limit}箱哦～`);
        },

        updateProduct(data, counts) {
            if (data.formats) {
                const dFormates = data.formats;
                for (let i = 0; i < dFormates.length; i++) {
                    const df = dFormates[i];
                    const { id } = df;
                    df.count = counts[id].count;
                }
            }
            return data;
        },

        async onProductChange(val) {
            const { totalPrice } = this;
            this.productItem = this.updateProduct(this.productItem, this.counts);

            this.$emit('update:quantity', val);
            this.$emit('productChange', { id: this.productItem.id, price: totalPrice, product: this.productItem });
        },
    },
};
