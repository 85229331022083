/**
 * 搜索页
 */
import EmptyList from '@/components/common/empty-list/index.vue';

export default {
  name: 'search-page',

  components: {
    EmptyList,
  },

  props: {
    list: {
      type: Array,
      default: () => ([]),
      required: true,
    },
  },

  computed: {

  },

  data() {
    return {
      keyword: '',   // 搜索关键字
    }
  },

  methods: {
    onSearchKeyword() {
      this.$emit("search", this.keyword);
    },

    // 清空关键字
    onDeleteKeyword() {
      this.keyword = ''
      this.$refs['keyword'].focus()
    },

    // 取消：取消搜索模式
    onCancel() {
      this.$emit("cancel");
    },

    onDetail(item) {
      this.$emit("ok", item);
    },

  },

  async created() {
    
  },
}
