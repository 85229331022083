//  商品列表
import { mapState } from 'vuex';
import { Dialog, Toast } from 'vant';
import OrderProductItem from '@/components/order/order-product-item/index.vue';
import AddressPicker from '@/components/common/address-picker/index.vue';
import UploadImage from '@/components/common/upload-image/index.vue';
import SearchPage from '@/components/order/search-page/index.vue';
import CustomButton from '@/components/common/custom-button/index.vue';
import { postOrder, getAgencyList, getRebateSystems } from '@/service/order/index.js';
import { postGift } from '@/service/cart/index';
import { getNationalExp } from '@/service/lipGuoqingTemp';
import { getFormats } from '@/utils/lipGuoqingTemp';

export default {
    name: 'order-dispatch',

    components: {
        OrderProductItem,
        AddressPicker,
        UploadImage,
        SearchPage,
        CustomButton,
    },

    data() {
        return {
            agencyList: [], // 经销商列表
            agencyTempList: [],
            agencyInfo: {
                name: '',
            },
            showArea: false,
            addressInfo: {
                userName: '',
                phone: '',
                address: '',
            },
            area: '',
            images: [],
            productMap: {},
            isSearchAgency: false,
            sendLoadig: false,
            showSelectGift: false, // 展示选择赠品的弹窗
            giftPackages: {}, // 当前礼包
            selectGifts: {}, // 选中的礼包
            currentProductId: '', // 当前操作产品
            allGiftMap: {}, // 所有礼包
            showExplain: false, // 展示说明
            giftCheckData: [
                {
                    name: '累计积分',
                    hint: '（积分可以换旅游名额）',
                    visible: false,
                },
                {
                    name: '挑选赠品',
                    hint: '',
                    visible: true,
                },
            ],
            showSystemPicker: false,
            systemlist: [],
            system: {},
            productsTemp: [],
        };
    },

    computed: {
        ...mapState({
            logo: state =>
                state.permission.permission['retail-order'] && state.permission.permission['retail-order'].logo,
            userId: state => state.user.user.id,
            brandId: status => 1, // status.global.brandId
            products: state => state.product.products,
            userRank: state =>
                state.user.user.levelall && state.user.user.levelall[0] && state.user.user.levelall[0].rank,
            params: state => state.params.paramsList,
        }),

        //  选中下单的商品
        selectedProducts() {
            return this.productsTemp.filter(p => p.count > 0);
        },

        //  已选商品件数
        sumCount() {
            return this.selectedProducts.reduce((sum, p) => {
                return sum + Number(p.count);
            }, 0);
        },

        productList() {
            let list = [];
            Object.keys(this.productMap).forEach(key => {
                list.push(this.productMap[key].product);
            });
            return list;
        },

        totalPrice() {
            let price = 0;
            Object.keys(this.productMap).forEach(key => {
                price = price + this.productMap[key].price;
            });
            return price;
        },

        // zvProductList() {
        //   if (this.productList.length !== 0) {
        //     return this.productList.map(val => {
        //       var obj = { productId: val.id, quantity: val.count };
        //       return obj;
        //     })
        //   }
        // },

        // 赠品数量
        giftCount() {
            let num = 0;
            Object.keys(this.giftPackages.giftObjs || {}).map(key => {
                this.giftPackages.giftObjs[key].map(d => {
                    if (d.isShow) {
                        num = num + d.count;
                    }
                });
            });
            return num;
        },

        // 是否选着了赠品
        chooseGift() {
            return this.giftCheckData[1].visible ? true : false;
        },

        // 是否开启积分赠品二选一选择
        isOpenGift() {
            const { params } = this;
            let visible = false;
            params.map(param => {
                if (param.value == 0) {
                    if (param.name === '赠品和积分并存') {
                        visible = true;
                    }
                }
            });
            return visible;
        },
    },

    methods: {
        onSubmit() {},
        // 选择经销商
        toShowAgencyChoose() {
            this.isSearchAgency = true;
            this.agencyList = this.agencyTempList;
        },

        handleSearchAgency(val) {
            this.agencyList = this.agencyTempList.filter(obj => obj.name.indexOf(val) >= 0);
        },

        handleAgencyCancel() {
            this.isSearchAgency = false;
        },

        async handleAgencyOK(val) {
            const { brandId, userId } = this;
            this.agencyInfo = val;
            this.isSearchAgency = false;
            // 清空数据
            this.$store.dispatch('orderDispatch/reset');
            await this.$store.dispatch('product/getProducts', { brandId, userId });
            this.addressInfo = {};
            this.area = '';
            this.images = [];
            this.productMap = {};
            let products = this.products.filter(d => d.systemId == this.system.id);
            products.map(d => (d.count = 0));
            this.productsTemp = [...products];
        },

        getAgencyInfoList(systemId) {
            const { userId } = this;
            getAgencyList({ userId, systemId })
                .then(res => {
                    this.agencyList = res || [];
                    this.agencyTempList = res || [];
                    this.agencyInfo = res[0] || {};
                })
                .catch(err => {
                    console.error(err);
                    this.agencyList = [];
                    this.agencyTempList = [];
                });
        },

        // 选择所属地区
        toShowAreaChoose() {
            this.showArea = true;
            this.$refs['addressPicker'].open();
        },

        handleAddressChange(region) {
            if (region && region.id) {
                this.area = this.$getFullAddress(region);
            } else {
                this.area = '';
            }
        },

        handleProductChange(val) {
            this.productsTemp.map(d => {
                if ((d.id = val.id)) {
                    d.count = val.product.count;
                }
            });
            this.productsTemp = [...this.productsTemp];
            // 该产品已经取消了
            if (val.price === 0 && this.productMap[val.id]) {
                this.$delete(this.productMap, val.id);
            } else {
                this.$set(this.productMap, val.id, JSON.parse(JSON.stringify(val)));
            }

            // 获取选中的赠品
            this.getAllGift();
        },

        getZvProductList() {
            let list = [];
            if (this.productList.length !== 0) {
                list = this.productList.map(val => {
                    var obj = {
                        productId: val.id,
                        quantity: val.count,
                        formatId: val.formats[0].id,
                    };
                    return obj;
                });
            }
            return list;
        },

        // 点击按钮，返回顶部
        returnTop() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },

        onFailed() {
            this.returnTop();
        },

        //  跳转到发货页面
        async toSend(type) {
            const {
                brandId,
                agencyInfo,
                addressInfo,
                productList,
                totalPrice,
                userRank,
                images,
                selectedProducts,
                allGiftMap,
                chooseGift,
                system,
            } = this;

            if (!agencyInfo.id) {
                this.returnTop();
                return Toast('请选择要下单的经销商！');
            }

            // if (!this.addressInfo.userName) {
            //     Toast("请填写收货人名称！");
            //     return;
            // }
            // if (!this.addressInfo.phone) {
            //     Toast("请填写收货人手机号码！");
            //     return;
            // } else {
            //     if (!(/^1[3456789]\d{9}$/.test(this.addressInfo.phone))) {
            //         Toast("手机号码有误，请重填");
            //         return;
            //     }
            // }
            // if (!this.area && type === 'zl') {
            //     Toast("请填写所属地区！");
            //     return;
            // }
            // if (!this.addressInfo.address && type === 'zl') {
            //     Toast("请填写详细地址！");
            //     return;
            // }

            if (this.images.length === 0 && type === 'zl') {
                this.returnTop();
                Toast('请上传转账凭证！');
                return;
            }
            if (!this.selectedProducts.length) {
                Toast('至少选中一个商品才发货～');
                return;
            }
            if (type === 'zl') {
                // 判断有没选择赠品
                const isChoosed = await this.checkIsSelectGift();
                if (chooseGift && !isChoosed) {
                    return;
                }

                // 重构个人系信息
                let { userName, phone, address } = addressInfo;
                address = this.area.replace(/\s+/g, '') + address;
                let addressObj = {
                    name: userName,
                    phone,
                    fullAddress: address,
                };
                try {
                    await Dialog.confirm({ message: '确定提交订单吗～' });
                    this.sendLoadig = true;
                    const params = {
                        userId: agencyInfo.userId,
                        brandId: brandId,
                        totalPrice: totalPrice,
                        payType: 'RECEIPT',
                        address: addressObj,
                        subOrders: [
                            {
                                receipts: images.map(val => val.serverId),
                                products: this._wrapProducts(selectedProducts),
                                gifts: this._wrapGifts(allGiftMap),
                                type: 0,
                                totalPrice: totalPrice,
                                systemId: system.id,
                                chooseGift: this.chooseGift,
                            },
                        ],
                    };

                    postOrder(params)
                        .then(data => {
                            this.sendLoadig = false;
                            this.$router.push({
                                name: 'order-delivery-success',
                                query: { orderId: data[0] },
                            });
                        })
                        .catch(err => {
                            this.sendLoadig = false;
                        });
                } catch (error) {
                    this.sendLoadig = false;
                    console.log(error);
                }
            }
            if (type === 'zv') {
                //  将选中的商品信息保存到vuex中
                let zvProductList = this.getZvProductList();
                this.$store.commit('orderDispatch/setField', {
                    junior: agencyInfo,
                    addressInfo: addressInfo,
                    productList: zvProductList,
                    totalPrice: totalPrice,
                });
                this.$router.push({ name: 'order-dispatch-terminal' });
            }
        },

        showZV() {
            // 总代、vip 和战略以上级别显示不一样界面
            let levelCode = this.agencyInfo.levelCode;
            if (!levelCode) return false;
            if (levelCode === 'QGZDL' || levelCode === 'BJVIP') {
                return 'zv';
            } else {
                return 'zl';
            }
        },

        async init() {
            const { brandId, userId } = this;

            this.getSystemlist();
            await this.$store.dispatch('product/getProducts', { brandId, userId });
            this.$store.dispatch('product/getLimitProductCount', { brandId, userId });
        },

        //  选中某个赠品组合
        onSelectGift(key, j) {
            this.giftPackages.giftObjs[key][j].isShow = !this.giftPackages.giftObjs[key][j].isShow;
            this.giftPackages = { ...this.giftPackages };
        },

        hide() {
            this.showSelectGift = false;
        },

        // 选择赠品 - 弹窗
        async selectGift() {
            // 判断规则是否全选获取只能多选一
            let message = '';
            var isLength = Object.keys(this.giftPackages.giftObjs).every(
                key => this.giftPackages.giftObjs[key].length === 1
            );
            if (isLength) {
                // 同存
                for (let key of Object.keys(this.giftPackages.giftObjs)) {
                    if (!this.giftPackages.giftObjs[key][0].isShow) {
                        // 只要有一个没选择就提示
                        Object.keys(this.giftPackages.giftObjs).forEach(i => {
                            this.giftPackages.giftObjs[i].map(d => {
                                message += `<span>${d.productName}</span>，`;
                            });
                        });
                        message = message.substr(0, message.length - 1);
                        message = message + '<p>需要全选</p>';
                        await Dialog.alert({
                            message,
                            title: '温馨提示',
                        });
                        return;
                    }
                }
            } else {
                // 互斥
                for (let key of Object.keys(this.giftPackages.giftObjs)) {
                    // 存在互斥情况的数据
                    if (this.giftPackages.giftObjs[key].length > 1) {
                        var trueCount = this.giftPackages.giftObjs[key].reduce((res, gift) => {
                            if (gift.isShow) {
                                return (res += 1);
                            }
                            return (res += 0);
                        }, 0);
                        // 同一组的要每一组一个
                        if (trueCount === 0) {
                            message = '亲，同一组的只能多选一，不同组的必须全选~';
                            await Dialog.alert({
                                message,
                                title: '温馨提示',
                            });
                            return;
                        }
                        if (trueCount > 1) {
                            this.giftPackages.giftObjs[key].map(d => {
                                message += `<span>${d.productName}</span>；`;
                            });
                            message = message.substr(0, message.length - 1);
                            message = message + '<p>只能选一个</p>';
                            await Dialog.alert({
                                message,
                                title: '温馨提示',
                            });
                            return;
                        }
                    } else {
                        // 其他不互斥的也要求全选
                        for (let i of Object.keys(this.giftPackages.giftObjs)) {
                            if (
                                this.giftPackages.giftObjs[i].length === 1 &&
                                !this.giftPackages.giftObjs[i][0].isShow
                            ) {
                                message = '亲，同一组的只能多选一，不同组的必须全选~';
                                await Dialog.alert({
                                    message,
                                    title: '温馨提示',
                                });
                                return;
                            }
                        }
                    }
                }
            }

            this.giftPackages = { ...this.giftPackages };
            const selectGifts = { ...this.giftPackages };

            this.showSelectGift = true;

            // 处理选中礼包后商品数据
            this.formatsSelectedGifts(selectGifts);
        },

        // 格式化赠品
        formatsSelectedGifts(selectGifts) {
            let packages = this.allGiftMap[this.system.id] || [];
            if (packages.length) {
                packages.map(packageobj => {
                    packageobj.gifts.map(gift => {
                        selectGifts.giftObjs[gift.value].map(sgift => {
                            if (gift.formatId === sgift.formatId) {
                                gift.isShow = sgift.isShow;
                            }
                        });
                    });
                });
            }
            this.$set(this.allGiftMap, this.system.id, JSON.parse(JSON.stringify(packages)));
        },

        // 校验是否选择了赠品，检查赠品/体验装数量
        async checkIsSelectGift() {
            let message = '';

            // 需要选赠品（默认一个产品线）
            if (!message && this.allGiftMap[1] && this.allGiftMap[1].length !== 0) {
                this.allGiftMap[1].forEach(item => {
                    let isSelect = item.gifts.some(g => g.isShow);
                    if (!isSelect) {
                        var name = item.packageName;
                        message = `亲，您（${name}）还未选择赠品，赶紧去选择吧～`;
                    }
                });
            }

            if (message) {
                try {
                    await Dialog.alert({
                        message,
                    });
                } catch (e) {
                    return false;
                }
                return false;
            }
            return true;
        },

        // 商品数据
        _wrapProducts(products) {
            const { system } = this;
            let temp = [];
            if (products.length) {
                products.map(product => {
                    product.formats.map(format => {
                        var p = {
                            productId: format.productId,
                            productName: product.name,
                            formatId: format.id,
                            formatName: format.name,
                            systemId: system.id,
                            unit: product.unit,
                            thumb: product.thumb,
                            quantity: format.count,
                            warehouseQuantity: 0,
                        };
                        temp.push(p);
                    });
                });
            }
            return temp;
        },

        // 礼包数据（只有单个产品线）
        _wrapGifts(allGiftMap) {
            const { system } = this;
            let temp = [];
            if (allGiftMap[system.id] && allGiftMap[system.id].length) {
                allGiftMap[system.id].map(packageobj => {
                    if (packageobj.gifts) {
                        packageobj.gifts.map(gift => {
                            var p = {
                                productId: gift.productId,
                                productName: gift.productName,
                                formatId: gift.formatId,
                                formatName: gift.formatName,
                                systemId: system.id,
                                unit: gift.unit,
                                thumb: gift.thumb,
                                quantity: gift.quantity * packageobj.quantity,
                                packageId: packageobj.packageId,
                                mainId: packageobj.mainId,
                                warehouseQuantity: 0,
                            };
                            temp.push(p);
                        });
                    }
                });
            }
            return temp;
        },

        // 获取全部活动赠品
        async getAllGift() {
            const { productList, userId } = this;
            let list = [];
            if (productList.length) {
                productList.map(product => {
                    list.push({ productId: product.id, quantity: product.count });
                });
            }
            let params = { list, userId };
            const { systemIdGiftMap } = await postGift(params);
            this.allGiftMap = this.chooseGift ? systemIdGiftMap : {};
        },

        // 选中赠品
        onGiftFormat(gift) {
            let giftTemp = { ...gift, giftObjs: {} };
            giftTemp.gifts.map(gift => {
                gift.isShow = gift.isShow ? gift.isShow : false;
                gift.count = gift.quantity * giftTemp.quantity;
                if (!giftTemp.giftObjs[gift.value]) giftTemp.giftObjs[gift.value] = [];
                giftTemp.giftObjs[gift.value].push(gift);
            });
            delete giftTemp.gifts;
            this.giftPackages = { ...giftTemp };

            this.showSelectGift = true;
        },

        // 打开说明弹窗
        handleExplain() {
            this.showExplain = true;
        },

        handleExplainOk() {
            this.showExplain = false;
        },

        // 选中是否要赠品
        onGiftCheck(i) {
            this.giftCheckData.map((d, j) => {
                if (i == j) {
                    d.visible = true;
                } else {
                    d.visible = false;
                }
            });
            this.giftCheckData = [...this.giftCheckData];
            if (this.giftCheckData[0].visible) this.allGiftMap = {};
        },

        // 获取产品线
        async getSystemlist() {
            try {
                const systemlist = (await getRebateSystems()) || [];
                let list = systemlist.map(item => ({ text: item.name, id: item.id }));
                this.system = list[0];
                this.systemlist = list;
                let products = this.products.filter(d => d.systemId == list[0].id);
                this.productsTemp = [...products];
                this.getAgencyInfoList(list[0].id);
            } catch (e) {}
        },

        // 产品线
        onSystemConfirm(data) {
            this.system = data;
            this.getAgencyInfoList(data.id);
            let products = this.products.filter(d => d.systemId == data.id);
            products.map(d => (d.count = 0));
            this.productsTemp = [...products];
            this.$refs['orderProductItem'][0].reload();
            this.productMap = {};
            this.allGiftMap = {};
            this.showSystemPicker = false;
        },
    },

    created() {
        //  清除预订单
        this.$store.dispatch('orderDispatch/reset');
        this.init();
    },
};
